<template>
  <v-container fluid>
      <div class="colorlib-intro">
            <h1>Impressum</h1>
            <h2>Angaben gem&auml;&szlig; &sect; 5 TMG:</h2>
            <p>Jens Koch<br></p>
            <h3>Postanschrift:</h3>
            <p>Unterdorf 13<br>37434 L&uuml;tgenhausen<br></p>
            <h3>Kontakt:</h3>
            <p>Telefon: 05529/1024<br>E-Mail: info@ko-mi-trans.de</p>
            <h3>Vertreten durch:</h3>
            <h2>Berufsrechtliche Angaben </h2>
            <h3>Die Berufshaftpflichtversicherung wurde abgeschlossen bei:</h3>
            <p>W&uuml;rttembergische</p>
            <h3>Geltungsbereich des Versicherungsschutzes:</h3>
            <p>Deutschland<br></p>
            <h2>Hinweise zur Website</h2>
            <h3>Urheberrechtliche Hinweise</h3>
            <p>Jens Koch<br></p>
            <h2>Information gem&auml;&szlig; &sect; 36 VSBG</h2>
            <p>Gem&auml;&szlig; &sect; 36 VSBG (Verbraucherstreitbeilegungsgesetz – Gesetz &uuml;ber die alternative Streitbeilegung in Verbrauchersachen) erkl&auml;rt der Betreiber dieser Website:</p>
            <p>Wir sind weder bereit noch verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
            <p><em>Das Impressum wurde mit dem <a href="https://www.activemind.de/datenschutz/impressums-generator/">Impressums-Generator der activeMind AG</a> erstellt.</em></p>
      </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
 
    };
  },
  
  created() {

  },

  methods: {

  },
  computed: {

  },
  mounted(){ }
};
</script>

<style lang="scss" scoped></style>